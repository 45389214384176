import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
  center: {
    lat: -37.8087806,
    lng: 145.0038567,
  },
  zoom: 13,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
  <div className="google-map-holder d-print-none">
    <GoogleMapReact
      
      bootstrapURLKeys={{ key: 'AIzaSyAYDpZzVvNlz4ZVJRPemS3LdVkLOuGnvKs' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <div className="map-marker"><img src={'/ico-pin.svg'} width="51" height="74" alt="" /></div>
    </GoogleMapReact>
  </div>
)

export default GoogleMap