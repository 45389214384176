import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"
import GoogleMap from '../components/GoogleMap'
import { navigate } from 'gatsby-link'

function encode(data) {
   return Object.keys(data)
     .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
     .join('&')
 }


 export default class LetsTalk extends React.Component {
   constructor(props) {
     super(props)
     this.state = { isValidated: false }
     const pageContext  = props.pageContext
   }
 
   handleChange = (e) => {
     this.setState({ [e.target.name]: e.target.value })
   }
 
   handleSubmit = (e) => {
     e.preventDefault()
     const form = e.target
     fetch('/', {
       method: 'POST',
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       body: encode({
         'form-name': form.getAttribute('name'),
         ...this.state,
       }),
     })
       .then(() => navigate(form.getAttribute('action')))
       .catch((error) => alert(error))
   }
 
   render() {
     return (
    <Layout>
    <SEO description="To discuss your next digital project schedule a time for us to call you, use our online form or call us on 03 9429 1318. We would love to hear from you!"  title="Contact Us" />
    
		<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
			<div className="container">
				<ol className="breadcrumb mb-0 py-2 px-0">
					<li className="breadcrumb-item">
					<a href="/">Home</a></li>
					<li className="breadcrumb-item active">Contact Us</li>
				</ol>
			</div>
		</nav>
        <section className="intro-section pt-10 pb-5 pt-lg-26 pb-lg-15">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-7 col-lg-6 mb-8 mb-md-0">
                    <h1 className="mb-3 mb-4">Contact Us</h1>
                    <p className="contact-intro">To discuss your next project call us on <a href="tel:0394291318">(03) 9429 1318</a> or complete the form below. Alternatively you can email us at <a href="mailto:enquiries@webplace.com.au">enquiries@webplace.com.au</a>.</p>
                    </div>
                    <div className="col-12 col-md-5 col-lg-6 text-center text-md-right d-print-none">
                        <div className="img-holder"> 
                            <img src={'/intro-2.svg'} alt="" width="" height="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

<section className="form-section">
<div className="container">
   
   <div className="tab_surround pl-sm-30 pl-5 pr-sm-30 pr-5 pt-10 pb-20">
      <div className="contact-block" id="contact">
         <div className="frm_forms " id="frm_form_2_container">
            <form name="contact" method="POST" onSubmit={this.handleSubmit} netlify="true" data-netlify-honeypot="bot-field" action="/thankyou/">
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
               <input type="hidden" name="form-name" value="contact" />
               <div hidden>
               <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
               </label>
               </div>
               <div  className="frm_form_fields ">
                  
                     <section className="form-section">
                        <div className="container">
                           <h2 className="section-heading text-default text-center mb-5 mb-md-10 w-700">Email us</h2>
                           <p className="text-center">If you have a new Project or General Enquiry, you can use this form to contact us. <br />Alternatively you can email us at <a href="mailto:enquiries@webplace.com.au">enquiries@webplace.com.au</a>.</p>
                           <p className="text-center">If you would like to speak with someone directly, please call us on <a href="tel:0394291318">03 9429 1318</a></p>
                           <div className="form-wrap">
                              <div className="frm_fields_container">
                                 <div className="form-group">
                                    <label htmlFor={'name'} className="frm_primary_label">Name <span className="frm_required">*</span> </label>
                                    <div className="input-holder position-relative">
                                       <input type={'text'}
                                       name={'name'}
                                       onChange={this.handleChange}
                                       id={'name'}
                                       required={true}
                                       className="form-control data-hj-allow" 
                                       />
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" /></div>
                                    </div>
                                    <div className="error-msg name_error">Please enter your name</div>
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor={'email'} className="frm_primary_label">Email address <span className="frm_required">*</span> </label>
                                    <div className="input-holder position-relative">
                                       <input type={'email'}
                                       name={'email'}
                                       onChange={this.handleChange}
                                       id={'email'}
                                       required={true} 
                                       className="form-control data-hj-allow"  />
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" /></div>
                                    </div>
                                    <div className="error-msg email_error">Please enter your email address</div>
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor={'company'} className="frm_primary_label">Company <span className="frm_required"></span> </label>
                                    <div className="input-holder position-relative">
                                       <input type={'text'}
                                       name={'company'}
                                       onChange={this.handleChange}
                                       id={'company'}
                                       required={true}
                                       className="form-control data-hj-allow" />
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" /></div>
                                    </div>
                                    <div className="error-msg">Please enter your Company</div>
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor={'phone'} className="frm_primary_label">Phone number <span className="frm_required">*</span> </label>
                                    <div className="input-holder position-relative">
                                    <input 
                                    type={'text'}
                                    name={'phone'}
                                    onChange={this.handleChange}
                                    id={'phone'}
                                    required={true}
                                    className="form-control data-hj-allow" />
                                       <div className="ico-error position-absolute"> 
                                          <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" />
                                       </div>
                                    </div>
                                    <div className="error-msg tel_error">Please enter your phone number</div>
                                 </div>
                                 <div className="how_did_you_find_us">
                                    <label htmlFor={'phone'} className="frm_primary_label"> How did you hear about us? <span className="frm_required">*</span> </label>
                                    <div className="input-holder position-relative">
                                       <select 
                                       name={'how_did_you_find_us'}
                                       onBlur={this.handleChange}
                                       id={'how_did_you_find_us'}
                                       required={true}
                                       data-reqmsg="This field cannot be blank." aria-required="true" data-invmsg="How did you hear about us? is invalid" className="form-control data-hj-allow jcf-hidden">
                                          <option value="Google search" className=""> Google search</option>
                                          <option value="Facebook" className=""> Facebook</option>
                                          <option value="Twitter" className=""> Twitter</option>
                                          <option value="LinkedIn" className=""> LinkedIn</option>
                                          <option value="Recommendation" className=""> Recommendation</option>
                                          <option value="Email" className=""> Email</option>
                                          <option value="Other" className=""> Other</option>
                                       </select>
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor={'google'} className="frm_primary_label">If you found us on Google, what search term did you use? <span className="frm_required"></span> </label>
                                    <div className="input-holder position-relative">
                                    <input 
                                    type={'text'}
                                    name={'google'}
                                    onChange={this.handleChange}
                                    id={'google'}
                                    required={false}
                                    className="form-control data-hj-allow" />
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" />
                                       </div>
                                    </div>
                                    <div className="error-msg message_error">Please enter your message</div>
                                 </div>
                                 <div className="form-group">
                                    <label htmlFor={'message'} className="frm_primary_label">Message <span className="frm_required">*</span> </label>
                                    <div className="input-holder position-relative">
                                       <textarea 
                                       name={'message'}
                                       onChange={this.handleChange}
                                       id={'message'}
                                       required={true}
                                       className="form-control data-hj-allow"  />
                                       <div className="ico-error position-absolute"> <img src={'error-icon.png'} srcSet={'error-icon@2x.png 2x'} alt="" width="18" height="18" />
                                       </div>
                                    </div>
                                    <div className="error-msg message_error">Please enter your message</div>
                                 </div>
                                 <input type="hidden" name="item_key" value="" />
                              </div>
                              <div className="btn-holder text-center pt-2 pt-lg-4"> <input type="submit" value="Submit" /></div>
                           </div>
                           <div className="loader">
                              <div className="holder"> <img src={'loading.gif'} alt="loading" width="170" height="170" />
                              </div>
                           </div>
                        </div>
                     </section>
                  
               </div>
               <div className="d-none">
                  <input name="bot-field" />
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
</section>
<section className="address-section py-10">
	<div className="container">
		<h2 className="section-heading text-default text-center mb-5 mb-md-10 w-700">Our address</h2>
		<div className="map-block">
			<div className="row mx-0">
				<div className="col-12 col-lg-6 px-0 position-relative">
               <GoogleMap />
				</div>
				<div className="col-12 col-lg-6 pt-8 pb-0 pt-md-10 pb-md-1 py-lg-0">
					<div className="address-holder d-lg-flex flex-wrap align-items-lg-center">
						<div className="w-100 py-lg-2">
							<ul className="address-list d-md-flex flex-wrap d-lg-block pt-lg-12">
								<li className="px-md-3 px-lg-0 mb-8 mb-lg-11">
									<div className="icon mb-2">
										<img src={'/city.png'} srcSet={'/city@2x.png 2x'} alt="" width="16" height="17" />
									</div>
									<h3 className="h3 text-capitalize w-500 border-bottom pb-2 mb-2">Office Address</h3>
									<div>58 Duke Street<br />Abbotsford VIC 3067</div>
								</li>
								<li className="px-md-3 px-lg-0 mb-8 mb-lg-11">
									<div className="icon mb-2">
										<img src={'/postal.png'} srcSet={'/postal@2x.png 2x'} alt="" width="18" height="19" />
									</div>
									<h3 className="h3 text-capitalize w-500 border-bottom pb-2 mb-2">Postal Address</h3>
									<address>WEBPLACE PTY LTD<br />PO BOX 3036<br />Burnley North LPO<br />Richmond VIC 3121</address>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

        



	</Layout>
   )
}
}